import React, { useEffect, useState } from "react";
import Container from "../../common/Container";
import Content from "../../common/Content";
import Icons from "../../config/icons";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import AppHeader from "../../common/AppHeader";
import { View } from "react-native";
import DashboardButton from "../../common/DashboardButton";
import { getAssesments } from "../../actions/AvailableAssesments";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../common/Loading";
import ResponsiveText from "../../common/ResponsiveText";
import colors from "../../config/color";

const AvailableAssessments = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { comingFrom } = route.params;
  const { platform } = useSelector((state) => state.UIState.platform);
  const [availableAList, setAvailableAList] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    const getAss = async () => {
      setLoading(true);
      const availableAsses = await dispatch(getAssesments());
      setLoading(false);
      setAvailableAList(availableAsses);
    };
    getAss();
  }, []);

  const handleNavigation = (item) => {
    if (comingFrom === "report") {
      navigation.navigate("Report", { assessmentCode: item });
    } else {
      navigation.navigate("InitiateAssesment", { selected: item });
    }
  };

  return (
    <Container>
      <AppHeader
        body="Available Assessments"
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <View
        style={[
          styles.container,
          { paddingHorizontal: platform ? wp("1%") : wp("5%") }
        ]}
      >
        {loading ? (
          <Loading />
        ) : !!availableAList.length ? (
          <Content>
            {availableAList.map((item, index) => {
              return (
                <DashboardButton
                  text={item.code}
                  onPress={() => handleNavigation(item)}
                />
              );
            })}
          </Content>
        ) : (
          <View style={styles.noData}>
            <ResponsiveText style={styles.noDataText}>
              No data found
            </ResponsiveText>
          </View>
        )}
      </View>
    </Container>
  );
};

export default AvailableAssessments;

const styles = {
  container: {
    flex: 1,
    justifyContent: "center"
  },
  noData: { flex: 1, justifyContent: "center", alignItems: "center" },
  noDataText: {
    fontWeight: "bold",
    alignText: "center",
    color: colors.borderColor
  },
  headerText: {
    textAlign: "center"
  }
};
