import { GET_USERS_SUCCESS } from "../../config/actionTypes";

const defaultState = {
  allUsers: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        allUsers: action.allUsers,
      };
    }
    default:
      return state;
  }
};
