import React from "react";
import { ActivityIndicator, View } from "react-native";
import colors from "../config/color";
import ResponsiveText from "./ResponsiveText";

function Loading(props) {
  return (
    <View style={styles.loading}>
      <ActivityIndicator size={"large"} color={colors.secondaryText} />
      <ResponsiveText style={styles.text}>Fetching Data</ResponsiveText>
    </View>
  );
}

export default Loading;
const styles = {
  loading: {
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    color: colors.secondaryText,
    textAlign: "center",
  },
};
