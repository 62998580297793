import { showMessage } from "react-native-flash-message";
import {
  RESEND_CODE_SUCCESS,
  SET_AUTH_TOKEN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_SUCCESS,
  USER_VERIFY_SUCCESS,
  SET_PLATFORM,
  FORGOT_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  USER_REPORT_SUCCESS
} from "../config/actionTypes";
import * as api from "../lib/api/UserService";

const showMessageData = { position: "top", autoHide: true, duration: 3000 };
export const login = (email, password) => async (dispatch) => {
  try {
    const userData = await api.login(email, password);
    dispatch(setUserData(userData.user));
    dispatch(setAuthToken(userData.token));
    return userData;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    dispatch({
      type: USER_LOGIN_ERROR,
      error: error.response.data.user
    });
    return error.response.data;
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    const data = await api.forgotPassword(email);
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      user: data.user
    });
    showMessage({
      ...showMessageData,
      message: data.message,
      type: "success"
    });
    return data;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    dispatch({
      type: USER_LOGIN_ERROR,
      error: error.response.data.user
    });
    return error.response.data;
  }
};

export const updatePassword = (newPassword) => async (dispatch) => {
  try {
    const data = await api.updatePassword(newPassword);
    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
      user: data
    });
    showMessage({
      ...showMessageData,
      message: data.message,
      type: "success"
    });
    return data;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const getUserProfile = () => async (dispatch) => {
  try {
    const userData = await api.getUserProfile();
    dispatch(setUserData(userData.user));
    return userData;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const register =
  (name, email, password, emp_code) => async (dispatch) => {
    try {
      const userData = await api.register(name, email, password, emp_code);
      dispatch({
        type: USER_REGISTER_SUCCESS,
        user: userData.user
      });
      showMessage({
        ...showMessageData,
        message: userData.message,
        type: "success"
      });
      return userData;
    } catch (error) {
      const message = error.response.data.message;
      showMessage({
        ...showMessageData,
        message,
        type: "danger"
      });
      return error.response.data;
    }
  };

export const setAuthToken = (token) => async (dispatch) => {
  dispatch({
    type: SET_AUTH_TOKEN,
    token
  });
};

export const setPlatform = (platform) => async (dispatch) => {
  dispatch({
    type: SET_PLATFORM,
    platform
  });
};

export const setUserData = (user) => async (dispatch) => {
  dispatch({
    type: USER_LOGIN_SUCCESS,
    user
  });
};

export const verifyCode = (email, code) => async (dispatch) => {
  try {
    const userData = await api.verifyCode(email, code);
    dispatch({
      type: USER_VERIFY_SUCCESS,
      user: userData.user,
      token: userData.token
    });
    dispatch(setAuthToken(userData.token));
    return userData;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};
export const resendCode = (email) => async (dispatch) => {
  try {
    const userData = await api.resendCode(email);
    dispatch({
      type: RESEND_CODE_SUCCESS,
      user: userData
    });
    showMessage({
      ...showMessageData,
      message: userData.message,
      type: "success"
    });
    return userData;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const getUserReport = (id) => async (dispatch) => {
  try {
    const data = await api.getUserReport(id);
    return data;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return false;
  }
};
