const colors = {
  primary: "#00DB80",
  secondary: "#9800FF",
  background: "#EDEDED",
  primaryText: "#FFFFFF",
  secondaryText: "#000000",
  thirdText: "#6D6D6D",
  buttonSecondaryColor: "#D02658",
  inputColor: "#858585",
  inActiveTabBarColor: "grey",
  activeTabBarColor: "#CE2A5A",
  onFocus: "#CE2A5A",
  borderColor: "#858585",
  placeholder: "#969696",
  subHeading: "#6D6D6D",
  separator: "#CCCCCC",
};
export default colors;
