import React from "react";
import { Text } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import Color from "../config/color";
import { isBrowserScreen } from "../utils/helpers";

const CommonText = (props) => {
  const { style, children, type } = props;
  let fontSize = isBrowserScreen() ? wp("1.5%") : wp("5%");
  let styles = {};
  if (type) {
    if (type == "heading") {
      fontSize = isBrowserScreen() ? wp("1.5%") : wp("5%");
      styles = {
        text: {
          color: Color.secondaryText,
          fontWeight: "bold",
          textAlign: "center"
        }
      };
    }
    if (type == "normal") {
      fontSize = isBrowserScreen() ? wp("1%") : wp("4%");
      styles = {
        text: {
          color: Color.secondaryText
        }
      };
    }
  }
  const numberOfLines = props.numberOfLines ? props.numberOfLines : 0;
  return (
    <Text
      numberOfLines={numberOfLines}
      style={{
        ...styles.text,
        ...props.style,
        ...{
          fontSize
        }
      }}
    >
      {children}
    </Text>
  );
};

export default CommonText;
