import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import MainStack from "./src/RootStack/MainStack";
import { Provider } from "react-redux";
import store from "./src/store";
import FlashMessage from "react-native-flash-message";

export default function App() {
  return (
    <Provider store={store}>
      <NavigationContainer>
        <MainStack />
        <FlashMessage position="top" />
      </NavigationContainer>
    </Provider>
  );
}
