import API from "./api";

// /available_assessment
export const getAssesments = async () => {
  const { data } = await API.get(`/api/available-assessment`);
  return data;
};

// /all-assessments
export const getAssignments = async () => {
  const { data } = await API.get(`/api/all-assessments`);
  return data;
};

// /getAllUsers
export const getAllUsers = async (query) => {
  const { assessmentCodeId, section, subjectUser } = query;
  const { data } = await API.get(
    `/api/users?assessmentCodeId=${assessmentCodeId}&section=${section}&subjectUser=${subjectUser}`
  );
  return data;
};

// /assign-assessment
export const assignAssessment = async (body) => {
  const { data } = await API.put(`/api/assign-assessment`, body);
  return data;
};

export const getAllAssessments = async () => {
  const { data } = await API.get(`/api/assessments`);
  return data;
};

export const updateQuestion = async (body) => {
  const { data } = await API.put(`/api/question`, body);
  return data;
};

// /my-assignments
export const getMyAssignments = async () => {
  const { data } = await API.get(`/api/my-assignments`);
  return data;
};

// /questions/all
export const initiateAssessment = async (body) => {
  const { data } = await API.post(`/api/questions/all`, body);
  return data;
};

// /submit-answer
export const submitAnswer = async (body) => {
  const { data } = await API.post(`/api/submit-answer`, body);
  return data;
};

// get all kpis
export const fetchKpis = async (body) => {
  const { data } = await API.get(`/api/my-KPIs?assessmentCodeId=${body}`);
  return data;
};

// kickoff kpis
export const kickOffKpis = async (body) => {
  const { data } = await API.post(`/api/kickOff`, body);
  return data;
};
