import * as api from "../lib/api/AssessmentService";
import { showMessage } from "react-native-flash-message";
import { showMessageData } from "./AvailableAssesments";

export const getAllAssessments = () => async (dispatch) => {
  try {
    const data = await api.getAllAssessments();
    dispatch({
      type: "GET_ALL_ASSESSMENTS_SUCCESS",
      assessments: data.assessments
    });
    return data.assessments;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const setAssessmentDetail = (data) => async (dispatch) => {
  dispatch({
    type: "SET_ASSESSEMENT_DETAIL",
    assessmentDetail: data
  });
};

export const updateQuestion = (data) => async (dispatch) => {
  try {
    const res = await api.updateQuestion(data);
    showMessage({
      ...showMessageData,
      message: res.message,
      type: "success"
    });
    return true;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const kickOffKpis = (data) => async (dispatch) => {
  try {
    const res = await api.kickOffKpis(data);

    showMessage({
      ...showMessageData,
      message: res.message,
      type: "success"
    });
    return res;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};
