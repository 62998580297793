import React, { useCallback, useState } from "react";
import AppHeader from "../../../common/AppHeader";
import Container from "../../../common/Container";
import Loading from "../../../common/Loading";
import Icons from "../../../config/icons";
import { View, FlatList } from "react-native";
import ResponsiveText from "../../../common/ResponsiveText";
import { useDispatch, useSelector } from "react-redux";
import { setAssessmentDetail } from "../../../actions/ManageAssessments";
import { getMyAssignments } from "../../../actions/AssignedAssessments";
import { useFocusEffect } from "@react-navigation/native";
import colors from "../../../config/color";
import Content from "../../../common/Content";
import ListItem from "./ListItem";

const AssignedAssessments = ({ navigation }) => {
  const dispatch = useDispatch();
  const { myAssignments } = useSelector((state) => state.AppState.assigned);
  const [loading, setLoading] = useState(false);

  useFocusEffect(
    useCallback(() => {
      setLoading(true);
      dispatch(getMyAssignments());
      setLoading(false);
    }, [])
  );

  const onAssignedDetail = (item, value) => {
    dispatch(setAssessmentDetail(item));

    navigation.navigate("AnswerQuestions", {
      section: value
    });
  };

  const renderItem = ({ item }) => {
    return <ListItem item={item} onAssignedDetail={onAssignedDetail} />;
  };

  return (
    <Container>
      <AppHeader
        body="Assigned Assessments"
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <View style={styles.container}>
        {loading ? (
          <Loading />
        ) : !!myAssignments.length ? (
          <Content>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={myAssignments}
              renderItem={renderItem}
              keyExtractor={(item) => {
                return item.user._id;
              }}
            />
          </Content>
        ) : (
          <View style={styles.noData}>
            <ResponsiveText style={styles.noDataText}>
              No data found
            </ResponsiveText>
          </View>
        )}
      </View>
    </Container>
  );
};

export default AssignedAssessments;

const styles = {
  container: {
    paddingHorizontal: 10,
    flex: 1,
    justifyContent: "center"
  },
  noData: { flex: 1, justifyContent: "center", alignItems: "center" },
  noDataText: {
    fontWeight: "bold",
    alignText: "center",
    color: colors.borderColor
  }
};
