import React, { useEffect, useState } from "react";
import { View } from "react-native";
import Container from "../../common/Container";
import Content from "../../common/Content";
import CommonText from "../../common/CommonText";
import AppHeader from "../../common/AppHeader";
import Icons from "../../config/icons";
import KpiForm from "../../components/KpiForm";
import { useDispatch } from "react-redux";
import {
  fetchKpis,
  initiateAssessment
} from "../../actions/AvailableAssesments";
import { updateQuestion } from "../../actions/ManageAssessments";
import Loading from "../../common/Loading";

const InitiateAssesment = ({ navigation, route }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [defaultKpis, setDefaultKpis] = useState([]);
  const selectedAssessment = route.params.selected;

  const onSubmit = async (data) => {
    let result;
    setLoading(true);
    if (defaultKpis.status === "update") {
      const answerObj = {};
      data.forEach((item) => {
        answerObj.questions = {
          ...answerObj.questions,
          [item._id]: { text: item.text }
        };
      });
      result = await dispatch(updateQuestion(answerObj));
    } else {
      const { _id } = selectedAssessment;
      const body = {
        assessmentCodeId: _id,
        questions: data
      };
      result = await dispatch(initiateAssessment(body));
    }
    setLoading(false);
    if (result) {
      navigation.goBack();
    }
  };

  useEffect(() => {
    fetchUserKpis();
  }, []);

  const fetchUserKpis = async () => {
    const res = await dispatch(fetchKpis(selectedAssessment._id));
    if (res.success) {
      if (res.questions.length) {
        setDefaultKpis({ ...res, status: "update" });
      } else {
        setDefaultKpis({
          questions: Array(10).fill({ text: "", kickedOff: false }),
          status: "create"
        });
      }
    }
  };

  return (
    <Container>
      <AppHeader
        body="Initiate Assessments"
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <View style={styles.container}>
        {defaultKpis?.questions?.length ? (
          <Content>
            <KpiForm
              loading={loading}
              editMode={defaultKpis}
              onSubmit={onSubmit}
            />
          </Content>
        ) : (
          <Loading />
        )}
      </View>
    </Container>
  );
};

export default InitiateAssesment;

const styles = {
  container: {
    paddingHorizontal: 10,
    flex: 1,
    justifyContent: "center"
  },
  content: {
    flex: 1
  },
  headerText: {
    textAlign: "center"
  }
};
