import React from "react";
import { Image, Platform } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import logoutIcon from "../assets/icons/logout.png";
import backIcon from "../assets/icons/back.png";
import down from "../assets/icons/down.png";
import up from "../assets/icons/up.png";
import check from "../assets/icons/check.png";
import sofit from "../assets/icons/sofit.png";
import uncheck from "../assets/icons/uncheck.png";
import rightArrow from "../assets/icons/rightArrow.png";
import { isBrowserScreen } from "../utils/helpers";

const styles = {
  defaultStyle: {
    height: isBrowserScreen() ? wp("2%") : wp("5%"),
    width: isBrowserScreen() ? wp("2%") : wp("5%"),
    resizeMode: "contain",
  },
  joinStyle: {
    height: wp("35%"),
    width: wp("35%"),
    resizeMode: "contain",
  },
};

const Icons = {
  splashIcon: (style = {}) => (
    <Image
      source={sofit}
      style={{
        ...styles.joinStyle,
        ...style,
      }}
    />
  ),
  logout: (style = {}) => (
    <Image
      source={logoutIcon}
      style={{
        ...styles.defaultStyle,
        ...style,
      }}
    />
  ),
  back: (style = {}) => (
    <Image
      source={backIcon}
      style={{
        ...styles.defaultStyle,
        ...style,
      }}
    />
  ),
  check: (style = {}) => (
    <Image
      source={check}
      style={{
        ...styles.defaultStyle,
        ...style,
      }}
    />
  ),
  unCheck: (style = {}) => (
    <Image
      source={uncheck}
      style={{
        ...styles.defaultStyle,
        ...style,
      }}
    />
  ),
  upArrow: (style = {}) => (
    <Image
      source={up}
      style={{
        ...styles.defaultStyle,
        ...style,
      }}
    />
  ),
  downArrow: (style = {}) => (
    <Image
      source={down}
      style={{
        ...styles.defaultStyle,
        ...style,
      }}
    />
  ),
  rightArrow: (style = {}) => (
    <Image
      source={rightArrow}
      style={{
        ...styles.defaultStyle,
        ...style,
      }}
    />
  ),
};

export default Icons;
