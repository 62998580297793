export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const GET_ASSESSMENTS_SUCCESS = "GET_ASSESSMENTS_SUCCESS";
export const GET_ASSIGNMENTS_SUCCESS = "GET_ASSIGNMENTS_SUCCESS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const ASSIGN_ASSESSMENT_SUCCESS = "ASSIGN_ASSESSMENT_SUCCESS";
export const GET_MY_ASSIGNMENTS_SUCCESS = "GET_MY_ASSIGNMENTS_SUCCESS";
export const INITIATE_ASSESSMENTS_SUCCESS = "INITIATE_ASSESSMENTS_SUCCESS";
export const SET_PLATFORM = "SET_PLATFORM";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const USER_REPORT_SUCCESS = "USER_REPORT_SUCCESS";
