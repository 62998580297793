import React from "react";
import {
  SafeAreaView,
  Image,
  Dimensions,
  StatusBar,
  View,
  Platform,
} from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import colors from "../config/color";
import { isBrowserScreen } from "../utils/helpers";

const Container = (props) => {
  const { statusBarColor, backgroundImageStyle } = props;
  let color = statusBarColor ? statusBarColor : colors.primaryText;
  let barStyle = props.barStyle ? props.barStyle : "dark-content";
  return (
    <SafeAreaView
      style={[
        styles.container,
        props.style,
        {
          maxWidth: isBrowserScreen() ? "40%" : "100%",
          minWidth: isBrowserScreen() ? "40%" : "100%",
          alignSelf: Platform.OS === "web" ? "center" : null,
        },
      ]}
    >
      <StatusBar backgroundColor={color} barStyle={barStyle} />
      {props.backgroundImage && (
        <Image
          source={props.backgroundImage}
          style={[styles.backgroundImage, backgroundImageStyle]}
        />
      )}
      {props.overlay && <View style={styles.overlayStyle} />}
      {props.children}
    </SafeAreaView>
  );
};

export default Container;

const styles = {
  container: {
    flex: 1,
    backgroundColor: colors.primaryText,
  },
  backgroundImage: {
    position: "absolute",
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  overlayStyle: {
    position: "absolute",
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
};
