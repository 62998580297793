import React, { useCallback, useEffect } from "react";
import { BackHandler, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../common/Container";
import CommonText from "../../common/CommonText";
import AppHeader from "../../common/AppHeader";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import DashboardButton from "../../common/DashboardButton";
import Icons from "../../config/icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";
import { getUserProfile } from "../../actions/User";

const Home = ({ navigation }) => {
  const userInfo = useSelector((state) => state.AppState.userData.userData);
  const { platform } = useSelector((state) => state.UIState.platform);
  const dispatch = useDispatch();

  const onlogoutPress = () => {
    AsyncStorage.clear();
    navigation.navigate("Login");
  };

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useFocusEffect(
    useCallback(() => {
      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        () => true
      );
      return () => backHandler.remove();
    }, [])
  );

  return (
    <Container>
      <AppHeader
        body="Assessment Manager"
        rightPress={onlogoutPress}
        right={Icons.logout()}
      />
      <View
        style={[
          styles.container,
          { paddingHorizontal: platform ? wp("1%") : wp("5%") }
        ]}
      >
        <CommonText style={styles.headerText} type="normal">
          Select your desired option
        </CommonText>
        <DashboardButton
          text="Initiate Assessment"
          onPress={() =>
            navigation.navigate("AvailableAssessments", {
              comingFrom: "initiateAssessment"
            })
          }
        />
        <DashboardButton
          text="Manage Assessments"
          onPress={() => navigation.navigate("ManageAssessment")}
          hidden={userInfo?.type === "user"}
        />
        <DashboardButton
          text="Manage Assignments"
          onPress={() => navigation.navigate("ManageAssignments")}
          hidden={userInfo?.type !== "admin"}
        />
        <DashboardButton
          text="Assigned Assessments"
          onPress={() => navigation.navigate("AssignedAssessments")}
        />
        <DashboardButton
          text="Report"
          onPress={() =>
            navigation.navigate("AvailableAssessments", {
              comingFrom: "report"
            })
          }
        />
      </View>
    </Container>
  );
};

export default Home;

const styles = {
  container: {
    flex: 1,
    justifyContent: "center"
  },
  headerText: {
    textAlign: "center",
    fontWeight: "bold"
  }
};
