import { Dimensions, Platform } from "react-native";

export const onValidate = (type, value) => {
  switch (type) {
    // regex accept only alphabets
    case "name":
      return /^[a-zA-Z ]*$/.test(value);
    // regex accept only alphanumeric
    case "emp_code":
      return /^[a-zA-Z0-9]*$/.test(value);
    case "email": {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        value
      );
    }
    case "number":
      return /^[0-9]*$/.test(value);
    case "boolean":
      // regex accept only true or false
      return /^([y|Y]es|[n|N]o)$/.test(value);
    case "password":
      return value.length >= 6;
    default:
      return false;
  }
};

// convert camel case string to title case i.e camelCase -> Camel Case
export const toTitleCase = (str) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

// check if platform and web and not mobile screen size
export const isBrowserScreen = () => {
  return Platform.OS === "web" && Dimensions.get("window").width > 600;
};

export const getQuestionCount = (questions) => {
  // return count if response or comment exists in questions array
  if (questions.length > 0) {
    return questions.reduce((acc, curr) => {
      if (curr.response || curr.comment) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }
};

export const renderPercentage = (obtained, total, percentage) => {
  return `${obtained}/${total} ${getPercentage(percentage)}`;
};

export const getPercentage = (percentage) => {
  return `- (${percentage}%)`;
};
