import { combineReducers } from "redux";
import user from "./user";
import assessments from "./Assessment";
import assignments from "./Assignments";
import assigned from "./AssignedAssessments";
import users from "./Users";
export default combineReducers({
  userData: user,
  assessments,
  assignments,
  assigned,
  users,
});
