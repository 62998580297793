import React, { useCallback, useState } from "react";
import {
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  Platform,
  BackHandler
} from "react-native";
import Container from "../../common/Container";
import InputField from "../../common/InputField";
import ResponsiveText from "../../common/ResponsiveText";
import CommonText from "../../common/CommonText";
import Button from "../../common/Button";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/User";
import { showMessage } from "react-native-flash-message";
import { onValidate } from "../../utils/helpers";
import { useFocusEffect } from "@react-navigation/native";

const showMessageData = { position: "top", autoHide: true, duration: 5000 };

const Login = ({ navigation }) => {
  const dispatch = useDispatch();
  const { platform } = useSelector((state) => state.UIState.platform);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeInput = (e, type) => {
    type === "login" ? setEmail(e) : setPassword(e);
  };

  useFocusEffect(
    useCallback(() => {
      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        () => true
      );
      return () => backHandler.remove();
    }, [])
  );

  const onLogin = async () => {
    const trimmedEmail = email.toLowerCase().trim();
    setEmail(email.trim());
    if (!trimmedEmail.length || !password.length) {
      showMessage({
        ...showMessageData,
        message: "Please fill all fields",
        type: "danger"
      });
    } else {
      if (!onValidate("email", trimmedEmail)) {
        showMessage({
          ...showMessageData,
          message: "Invalid Email",
          type: "danger"
        });
      } else if (!onValidate("password", password)) {
        showMessage({
          ...showMessageData,
          message: "Invalid Password",
          type: "danger"
        });
      } else {
        setLoading(true);
        const loginData = await dispatch(login(trimmedEmail, password));
        setLoading(false);
        if (loginData.success) {
          if (loginData.user.emailVerified) {
            navigation.navigate("Home");
          } else {
            navigation.navigate("VerifyOTP");
          }
        }
      }
    }
  };

  return (
    <Container style={styles.container}>
      <KeyboardAvoidingView
        behavior={platform ? "padding" : "height"}
        style={styles.container}
      >
        <View style={styles.headerContainer}>
          <CommonText type="heading">Assessment Manager</CommonText>
        </View>
        <View
          style={[
            styles.fieldContainer,
            { paddingHorizontal: platform ? wp("3%") : wp("5%") }
          ]}
        >
          <CommonText type="heading">Login with your credentials</CommonText>
          <InputField
            placeholder="Email"
            value={email}
            onChangeText={(e) => onChangeInput(e, "login")}
          />
          <InputField
            placeholder="Password"
            secureTextEntry
            value={password}
            onChangeText={(e) => onChangeInput(e, "password")}
          />
          <Button loading={loading} text="Login" onPress={onLogin} />
          <View style={styles.bottomView}>
            <TouchableOpacity
              style={{ alignItems: "center" }}
              onPress={() => navigation.navigate("Register")}
            >
              <ResponsiveText style={{ color: "red" }}>
                Register your account
              </ResponsiveText>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ alignItems: "center" }}
              onPress={() => navigation.navigate("ForgotPassword")}
            >
              <ResponsiveText style={{ color: "red" }}>
                Forgot Password?
              </ResponsiveText>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default Login;

const styles = {
  headerContainer: {
    height: wp("10%"),
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    flex: 1
  },
  fieldContainer: {
    flex: 1,
    justifyContent: "center"
  },
  bottomView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
};
