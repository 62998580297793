import API from "./api";

// get user profile
export const getUserProfile = async () => {
  const { data } = await API.get(`/api/user`);
  return data;
};

// login
export const login = async (email, password) => {
  const body = {
    email,
    password
  };

  const { data } = await API.post(`/login`, body);
  return data;
};

// register
export const register = async (name, email, password, emp_code) => {
  const body = {
    name,
    email,
    password,
    emp_code
  };

  const { data } = await API.post(`/register`, body);
  return data;
};

// /verify-user-code
export const verifyCode = async (email, code) => {
  const body = {
    email,
    verificationCode: code
  };

  const { data } = await API.post(`/verify-user-code`, body);

  return data;
};

// /resend-verification-email
export const resendCode = async (email) => {
  const body = {
    email
  };

  const { data } = await API.post(`/resend-verification-email`, body);
  return data;
};

// /forgot-password
export const forgotPassword = async (email) => {
  const body = {
    email
  };

  const { data } = await API.post(`/forgot-password`, body);
  return data;
};

//updatePassword
export const updatePassword = async (newPassword) => {
  const body = {
    newPassword
  };
  const { data } = await API.post(`/api/update-password`, body);
  return data;
};

// get user reports
export const getUserReport = async (id) => {
  const { data } = await API.get(`/api/report?assessmentCodeId=${id}`);
  return data;
};
