import React, { Component, useState } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  View,
  Platform,
} from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import colors from "../config/color";
import ResponsiveText from "./ResponsiveText";
import DatePicker from "react-native-datepicker";

const InputField = (props) => {
  const [borderBottomColor, setBorderBottomColor] = useState(
    colors.inActiveTabBarColor
  );
  const onFocus = () => {
    setBorderBottomColor(colors.onFocus);
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const onBlur = () => {
    setBorderBottomColor(colors.inActiveTabBarColor);
    if (props.onBlur) {
      props.onBlur();
    }
  };

  const today = new Date();
  const max_date = new Date(
    today.getFullYear() - 16,
    today.getMonth(),
    today.getDate()
  );
  return (
    <View style={[styles.container, props.containerStyle]}>
      {props.left && (
        <View style={[props.leftTextStyle, styles.leftTextStyle]}>
          <ResponsiveText style={[styles.leftText, props.textLeft]}>
            {props.left}
          </ResponsiveText>
        </View>
      )}
      {props.subLeft && (
        <View>
          <ResponsiveText style={styles.subLeftText}>
            {props.subLeft}
          </ResponsiveText>
        </View>
      )}
      <View
        style={[
          {
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: Platform.OS === "web" ? 0 : 10,
            borderWidth: 1,
            borderColor: borderBottomColor,
            borderRadius: 5,
          },
          props.inputContainer,
        ]}
      >
        {props.leftIcon && (
          <View style={[props.leftStyle, styles.leftStyle]}>
            {props.leftIcon}
          </View>
        )}
        {props.date ? (
          <DatePicker
            androidMode="spinner"
            style={{ width: "100%", paddingLeft: 10 }}
            date={props.value}
            mode="date"
            placeholder="Select Date"
            format="YYYY-MM-DD"
            minDate="1900-05-01"
            maxDate={max_date}
            confirmBtnText="Confirm"
            cancelBtnText="Cancel"
            customStyles={{
              dateIcon: {
                position: "absolute",
                right: 0,
                top: 0,
                marginLeft: 0,
              },
              dateInput: {
                position: "absolute",
                left: 0,
                top: 0,
                borderWidth: 0,
                // marginLeft: 36
              },
              placeholderText: {
                fontSize: 15,
                color: colors.placeholder,
              },
              // ... You can check the source to find the other keys.
            }}
            onDateChange={props.onChangeText}
          />
        ) : (
          <TextInput
            onChangeText={props.onChangeText}
            style={[
              Platform.OS === "web" ? styles.webInput : styles.inputField,
              props.inputField,
              { borderBottomColor: borderBottomColor },
            ]}
            placeholder={props.placeholder}
            underlineColorAndroid={"transparent"}
            placeholderTextColor={colors.inputColor}
            value={props.value}
            keyboardType={props.keyboardType ? props.keyboardType : "default"}
            secureTextEntry={
              props.secureTextEntry ? props.secureTextEntry : false
            }
            multiline={props.multiline}
            numberOfLines={props.numberOfLines ? 5 : 1}
            onBlur={onBlur}
            onFocus={onFocus}
            editable={props.editable}
            returnKeyType={props.search}
            onSubmitEditing={props.onSubmit}
            maxLength={props.maxLength}
          />
        )}
        {props.rightIcon && (
          <TouchableOpacity
            onPress={props.onRightPress}
            style={[props.rightStyle, styles.rightStyle]}
          >
            {props.rightIcon}
          </TouchableOpacity>
        )}
      </View>
      {props.bottomLeft && (
        <TouchableOpacity
          onPress={props.onForgetPress}
          style={[props.bottomLeftStyles, styles.bottomLeftStyles]}
        >
          <ResponsiveText
            style={{
              color: colors.primary,
              paddingVertical: 5,
            }}
          >
            {props.bottomLeft}
          </ResponsiveText>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default InputField;
const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  },
  leftStyle: {
    paddingRight: 10,
    marginLeft: 10,
  },
  inputField: {
    flex: 1,
    width: wp("100%"),
    fontSize: wp("4%"),
    color: colors.secondaryText,
    paddingLeft: 10,
    maxHeight: 120,
    textAlignVertical: "center",
  },
  webInput: {
    width: "100%",
    paddingVertical: 8,
    paddingLeft: 5,
  },
  inputLabel: {
    color: colors.placeholder,
    fontSize: wp("20%"),
  },
  rightStyle: {
    marginRight: 10,
  },
  leftTextStyle: {
    marginHorizontal: 10,
    color: "black",
  },
  leftText: {
    color: colors.secondaryText,
    fontWeight: "400",
    fontSize: wp("1.1%"),
  },
  subLeftText: {
    color: colors.SubHeading,
    fontSize: wp("0.8%"),
    marginLeft: 10,
  },
});
