import React from "react";
import { TouchableOpacity } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useSelector } from "react-redux";
import colors from "../config/color";
import ResponsiveText from "./ResponsiveText";

const DashboardButton = (props) => {
  const { platform } = useSelector((state) => state.UIState.platform);

  if (props.hidden) {
    return null;
  }
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[styles.container, { height: platform ? wp("5%") : wp("20%") }]}
    >
      <ResponsiveText style={styles.text}>{props.text}</ResponsiveText>
    </TouchableOpacity>
  );
};

export default DashboardButton;

const styles = {
  container: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.background,
    shadowColor: colors.secondaryText,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    marginVertical: 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.background,
  },
  text: {
    color: colors.secondaryText,
  },
};
