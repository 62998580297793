import React, { useCallback, useState, useEffect } from "react";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import AppHeader from "../../common/AppHeader";
import Container from "../../common/Container";
import Loading from "../../common/Loading";
import Icons from "../../config/icons";
import { TouchableOpacity, View, FlatList } from "react-native";
import ResponsiveText from "../../common/ResponsiveText";
import { useDispatch } from "react-redux";
import { setAssessmentDetail } from "../../actions/ManageAssessments";
import { useFocusEffect } from "@react-navigation/native";
import colors from "../../config/color";
import Content from "../../common/Content";
import {
  toTitleCase,
  getQuestionCount,
  isBrowserScreen,
  renderPercentage,
  getPercentage
} from "../../utils/helpers";
import { getUserReport } from "../../actions/User";

const Report = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { assessmentCode } = route.params;
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);

  useFocusEffect(
    useCallback(async () => {
      fetchReport();
    }, [])
  );

  const fetchReport = async () => {
    setLoading(true);
    const res = (await dispatch(getUserReport(assessmentCode._id))) || [];
    if (res) {
      setReport([...res.assessments]);
    }
    setLoading(false);
  };

  const onAssignedDetail = (item, value) => {
    dispatch(setAssessmentDetail(item));
    navigation.navigate("AnswerQuestions", {
      section: value,
      readOnly: true
    });
  };

  const renderItem = ({ item }) => {
    return (
      <View key={item.user._id}>
        <View style={styles.header}>
          <ResponsiveText style={styles.text}>
            {item.user.name} {getPercentage(item?.score?.total || "")}
          </ResponsiveText>
          <ResponsiveText style={styles.text}>
            {item.assessment.code}
          </ResponsiveText>
        </View>
        {Object.entries(item.questions).map(([section, value], index) => {
          return (
            <TouchableOpacity
              key={index + 1}
              style={[
                styles.sectionHeader,
                {
                  borderBottomWidth:
                    Object.keys(item.questions).length - 1 === index ? 0 : 1
                }
              ]}
              onPress={() => onAssignedDetail(item, section)}
            >
              <ResponsiveText style={styles.kpiText}>
                {toTitleCase(section.replace("_", " "))}
                <ResponsiveText style={styles.totalCount}>
                  {(section === "section_1" || section === "section_3") &&
                    ` - ${renderPercentage(
                      item?.score[section]?.obtained || 0,
                      item?.score[section]?.total || 0,
                      item?.score[section]?.percentage
                    )}`}
                </ResponsiveText>
              </ResponsiveText>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <ResponsiveText style={styles.assignedText}>
                  {`${getQuestionCount(value)}/${value.length}`}
                </ResponsiveText>
                {Icons.rightArrow()}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  return (
    <Container>
      <AppHeader
        body={assessmentCode?.code || "Report"}
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <View style={styles.container}>
        {loading ? (
          <Loading />
        ) : !!report?.length ? (
          <Content>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={report}
              renderItem={renderItem}
              keyExtractor={(item) => {
                return item.user._id;
              }}
            />
          </Content>
        ) : (
          <View style={styles.noData}>
            <ResponsiveText style={styles.noDataText}>
              No data found
            </ResponsiveText>
          </View>
        )}
      </View>
    </Container>
  );
};

export default Report;

const styles = {
  container: {
    paddingHorizontal: 10,
    flex: 1,
    justifyContent: "center"
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.background,
    paddingVertical: 20,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginTop: 10
  },
  sectionHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderBottomColor: colors.background,
    marginTop: 5
  },
  noData: { flex: 1, justifyContent: "center", alignItems: "center" },
  noDataText: {
    fontWeight: "bold",
    alignText: "center",
    color: colors.borderColor
  },
  text: {
    color: colors.SecondaryText
  },
  kpiText: {
    paddingVertical: 5,
    color: colors.SecondaryText,
    textAlighn: "center"
  },
  headerText: {
    color: colors.SubHeading
  },
  content: {
    backgroundColor: colors.primaryText,
    alignItems: "center",
    marginHorizontal: 25
  },
  assignedText: {
    fontSize: isBrowserScreen() ? wp("0.06%") : wp("0.9%"),
    color: colors.SecondaryText
  },
  totalCount: {
    fontSize: isBrowserScreen() ? wp("0.06%") : wp("0.9%"),
    color: colors.SecondaryText
  }
};
