import { GET_MY_ASSIGNMENTS_SUCCESS } from "../../config/actionTypes";

const defaultState = {
  myAssignments: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_MY_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        myAssignments: action.assessments,
      };
    }
    default:
      return state;
  }
};
