import * as api from "../lib/api/AssessmentService";
import { showMessage } from "react-native-flash-message";
import {
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSIGNMENTS_SUCCESS,
  GET_USERS_SUCCESS,
  ASSIGN_ASSESSMENT_SUCCESS,
  INITIATE_ASSESSMENTS_SUCCESS
} from "../config/actionTypes";

export const showMessageData = {
  position: "top",
  autoHide: true,
  duration: 3000
};

export const getAssesments = () => async (dispatch) => {
  try {
    const data = await api.getAssesments();
    dispatch({
      type: GET_ASSESSMENTS_SUCCESS,
      availableAssessments: data
    });
    return data.assessmentCode;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const initiateAssessment = (body) => async (dispatch) => {
  try {
    const data = await api.initiateAssessment(body);
    dispatch({
      type: INITIATE_ASSESSMENTS_SUCCESS,
      initiate: data
    });
    showMessage({
      ...showMessageData,
      message: data.message,
      type: "success"
    });
    return data;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const fetchKpis = (body) => async (dispatch) => {
  try {
    const data = await api.fetchKpis(body);
    return data;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const getAssignments = () => async (dispatch) => {
  try {
    const data = await api.getAssignments();
    dispatch({
      type: GET_ASSIGNMENTS_SUCCESS,
      allAssignments: data.assessments
    });
    return data.assessments;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const getAllUsers = (body) => async (dispatch) => {
  try {
    const data = await api.getAllUsers(body);
    dispatch({
      type: GET_USERS_SUCCESS,
      allUsers: data.user
    });
    return data.user;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};

export const assignAssessment = (body) => async (dispatch) => {
  try {
    const data = await api.assignAssessment(body);
    dispatch({
      type: ASSIGN_ASSESSMENT_SUCCESS,
      allUsers: data
    });
    showMessage({
      ...showMessageData,
      message: data.message,
      type: "success"
    });
    return data;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger"
    });
    return error.response.data;
  }
};
