import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FlatList, TouchableOpacity, View } from "react-native";
import colors from "../config/color";
import InputField from "./InputField";
import ResponsiveText from "./ResponsiveText";
import SelectItem from "./SelectItem";

const CustomSelect = ({ dataList, callback, multiSelect }) => {
  const [searchText, setSearchText] = useState(null);
  let [selected, setSelected] = useState([]);

  const _onSearch = (text) => {
    setSearchText(text.length > 0 ? text.toLowerCase() : null);
  };

  const _onSelect = (item) => {
    if (multiSelect) {
      if (selected.indexOf(item) == -1) {
        setSelected(selected.concat(item));
      } else {
        const filtered = selected.filter((i) => i != item);
        setSelected(filtered);
      }
    } else {
      const newArray = [];
      newArray.push(item);
      setSelected(newArray);
    }
  };

  useMemo(() => {
    dataList.forEach((item) => {
      if (item?.isAssigned) {
        _onSelect(item);
      }
    });
  }, [dataList]);

  useMemo(() => {
    multiSelect ? callback(selected) : callback(selected[0]);
  }, [selected]);

  const renderItem = ({ item }) => (
    <SelectItem
      selectedList={selected}
      item={item}
      onPress={() => _onSelect(item)}
      title={item.name}
    />
  );

  const filterArray = dataList
    .filter(function (item) {
      return item.name.toLowerCase().includes(searchText);
    })
    .map(function (item) {
      return item;
    });

  return (
    <View>
      <InputField
        placeholder={`Search`}
        value={searchText}
        onChangeText={_onSearch}
      />
      <FlatList
        showsVerticalScrollIndicator={false}
        data={!!searchText?.length ? filterArray : dataList}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
    </View>
  );
};

export default CustomSelect;
