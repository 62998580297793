// set app mode
const AppMode = [process.env.REACT_APP_ENV];

// set API URLs
const production = process.env.REACT_APP_BASE_URL;
const development =
  process.env.REACT_APP_BASE_URL ||  "https://dev-am-backend.sofit.ltd" //"https://am-backend.sofit.ltd"

let baseURL = "";

switch (AppMode[0]) {
  case "development":
    baseURL = development;
    break;
  case "production":
    baseURL = production;
    break;
  default:
    baseURL = development;
}

export default baseURL;
