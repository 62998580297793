import React, { useState } from "react";
import AppHeader from "../../../common/AppHeader";
import Container from "../../../common/Container";
import Loading from "../../../common/Loading";
import Icons from "../../../config/icons";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import KpiForm from "../../../components/KpiForm";
import {
  kickOffKpis,
  updateQuestion
} from "../../../actions/ManageAssessments";
import Button from "../../../common/Button";

const AssessmentDetail = ({ navigation }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingKickOff, setLoadingKickOff] = useState(false);
  const { assessmentDetail } = useSelector(
    (state) => state.UIState.assessments
  );

  const onSubmit = async (data) => {
    setLoading(true);
    const answerObj = {};

    data.forEach((item) => {
      answerObj.questions = {
        ...answerObj.questions,
        [item._id]: { text: item.text }
      };
    });

    const res = await dispatch(updateQuestion(answerObj));
    if (res) {
      setLoading(false);
      navigation.goBack();
    }
  };

  const handleKickOff = async () => {
    setLoadingKickOff(true);
    const body = {
      assessmentCodeId: assessmentDetail.assessment._id,
      subjectUser: assessmentDetail.user._id
    };
    const res = await dispatch(kickOffKpis(body));
    if (res.success) {
      navigation.goBack();
    }
    setLoadingKickOff(false);
  };

  return (
    <Container>
      <AppHeader
        body={`${assessmentDetail?.user?.name} (${assessmentDetail?.assessment?.code})`}
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <View style={styles.container}>
        {assessmentDetail ? (
          <KpiForm
            showKickOff
            loading={loading}
            loadingKickOff={loadingKickOff}
            editMode={assessmentDetail}
            onSubmit={onSubmit}
            handleKickOff={handleKickOff}
          />
        ) : (
          <Loading />
        )}
      </View>
    </Container>
  );
};

export default AssessmentDetail;

const styles = {
  container: {
    paddingHorizontal: 10,
    flex: 1,
    justifyContent: "center"
  }
};
