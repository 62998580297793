import { GET_ASSIGNMENTS_SUCCESS } from "../../config/actionTypes";

const defaultState = {
  allAssignments: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        allAssignments: action.allAssignments,
      };
    }
    default:
      return state;
  }
};
