import React, { useEffect, useState } from "react";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import AppHeader from "../../../common/AppHeader";
import Container from "../../../common/Container";
import Content from "../../../common/Content";
import Loading from "../../../common/Loading";
import Icons from "../../../config/icons";
import { FlatList, TouchableOpacity, View } from "react-native";
import ResponsiveText from "../../../common/ResponsiveText";
import colors from "../../../config/color";
import { useDispatch, useSelector } from "react-redux";
import { getAssignments } from "../../../actions/AvailableAssesments";
import { getQuestionCount, isBrowserScreen, toTitleCase } from "../../../utils/helpers";

const ManageAssignments = ({ navigation }) => {
  const dispatch = useDispatch();
  const { allAssignments } = useSelector((state) => state.AppState.assignments);
  const [loading, setLoading] = useState(false);

  const getAssignList = async () => {
    setLoading(true);
    await dispatch(getAssignments());
    setLoading(false);
  };

  useEffect(() => {
    getAssignList();
  }, []);

  const renderItem = ({ item, index }) => {
    return (
      <View>
        <View style={styles.header}>
          <ResponsiveText style={styles.text}>{item.user.name}</ResponsiveText>
          <ResponsiveText style={styles.text}>
            {item.assessment.code}
          </ResponsiveText>
        </View>
        {Object.entries(item.questions).map(([section, value], index) => {
          return (
            <TouchableOpacity
              style={[
                styles.sectionHeader,
                {
                  borderBottomWidth:
                    Object.keys(item.questions).length - 1 === index ? 0 : 1
                }
              ]}
              onPress={() =>
                navigation.navigate("UserSelection", {
                  selected: item,
                  selectedSection: value
                })
              }
            >
              <ResponsiveText style={styles.kpiText}>
                {toTitleCase(section.replace("_", " "))}
              </ResponsiveText>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <ResponsiveText style={styles.assignedText}>
                  {value.length && !!value[0]?.assigned_to ? " (Assigned)" : ""}{" "}
                  {`${getQuestionCount(value)}/${value.length}`}
                </ResponsiveText>
                {Icons.rightArrow()}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  return (
    <Container>
      <AppHeader
        body="Manage Assignments"
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <View style={styles.container}>
        {loading ? (
          <Loading />
        ) : !!allAssignments?.length ? (
          <Content>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={allAssignments}
              renderItem={renderItem}
              keyExtractor={(item) => item.id}
            />
          </Content>
        ) : (
          <View style={styles.noData}>
            <ResponsiveText style={styles.noDataText}>
              No data found
            </ResponsiveText>
          </View>
        )}
      </View>
    </Container>
  );
};

export default ManageAssignments;

const styles = {
  container: {
    paddingHorizontal: 10,
    flex: 1,
    justifyContent: "center"
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.background,
    paddingVertical: 20,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginTop: 10
  },
  sectionHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderBottomColor: colors.background,
    marginTop: 5
  },
  noData: { flex: 1, justifyContent: "center", alignItems: "center" },
  noDataText: {
    fontWeight: "bold",
    alignText: "center",
    color: colors.borderColor
  },
  text: {
    color: colors.SecondaryText
  },
  kpiText: {
    paddingVertical: 5,
    color: colors.SecondaryText,
    textAlighn: "center"
  },
  headerText: {
    color: colors.SubHeading
  },
  content: {
    backgroundColor: colors.primaryText,
    alignItems: "center",
    marginHorizontal: 25
  },
  assignedText: {
    fontSize: isBrowserScreen() ? wp("0.06%") : wp("0.9%"),
    color: colors.SecondaryText
  }
};
