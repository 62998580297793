import React from "react";
import {
  ScrollView,
  View,
  Dimensions,
  KeyboardAvoidingView,
} from "react-native";

const Content = (props) => {
  const style = props.style ? props.style : [];
  if (props.keyboardAvoidingView) {
    return (
      <KeyboardAvoidingView
        style={[styles.container, style]}
        refreshControl={props.refreshControl}
        showsVerticalScrollIndicator={false}
      >
        <View
          behavior={"position"}
          enabled={true}
          style={[styles.innerContainer, style]}
        >
          {props.children}
        </View>
      </KeyboardAvoidingView>
    );
  }
  return (
    <ScrollView
      style={[styles.container, style]}
      refreshControl={props.refreshControl}
      showsVerticalScrollIndicator={false}
      onScroll={props.onScroll}
    >
      <View style={[styles.container, style]}>{props.children}</View>
    </ScrollView>
  );
};
export default Content;
const styles = {
  container: {
    flex: 1,
  },
  innerContainer: {
    backgroundColor: "red",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
};
