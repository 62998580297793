import React, { useEffect, useState } from "react";
import { View } from "react-native";
import Container from "../../../common/Container";
import AppHeader from "../../../common/AppHeader";
import Icons from "../../../config/icons";
import {
  getAllUsers,
  assignAssessment,
} from "../../../actions/AvailableAssesments";
import { useDispatch, useSelector } from "react-redux";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import Button from "../../../common/Button";
import CustomSelect from "../../../common/CustomSelect";

const UserSelection = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { allUsers } = useSelector((state) => state.AppState.users);
  const subjectUser = route.params.selected;
  const subjectSection = route.params.selectedSection;
  const [assignedUser, setAssignedUser] = useState(null);

  const getUsersList = async () => {
    const body = {
      section: subjectSection[0].section,
      assessmentCodeId: subjectUser.assessment._id,
      subjectUser: subjectUser.user._id,
    };
    await dispatch(getAllUsers(body));
  };
  useEffect(() => {
    getUsersList();
  }, []);

  const selectedUsers = (res) => {
    setAssignedUser(res);
  };

  const onSubmit = async () => {
    const body = {
      section: subjectSection[0].section,
      assessmentCodeId: subjectUser.assessment._id,
      assignedTo: assignedUser._id,
      subjectUser: subjectUser.user._id,
    };
    const data = await dispatch(assignAssessment(body));
    data.success && navigation.goBack();
  };

  return (
    <Container>
      <AppHeader
        body="Select User"
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <View style={styles.container}>
        {!!allUsers?.length && (
          <CustomSelect dataList={allUsers} callback={selectedUsers} />
        )}
      </View>
      <Button containerStyle={styles.btn} onPress={onSubmit} text="Submit" />
    </Container>
  );
};

export default UserSelection;

const styles = {
  container: {
    paddingHorizontal: 10,
    paddingBottom: wp("10%"),
    flex: 1,
    overflow: "auto",
  },
  btn: {
    marginHorizontal: 10,
  },
};
