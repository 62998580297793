import { combineReducers } from "redux";
import loginForm from "./LoginFormState";
import assessments from "./Assessment";
import platform from "./Platform";

export default combineReducers({
  loginForm,
  assessments,
  platform,
});
