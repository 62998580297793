import React from "react";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import Icons from "../../../config/icons";
import { TouchableOpacity, View } from "react-native";
import ResponsiveText from "../../../common/ResponsiveText";
import colors from "../../../config/color";
import {
  getPercentage,
  isBrowserScreen,
  renderPercentage,
  toTitleCase
} from "../../../utils/helpers";

const ListItem = ({ onAssignedDetail, item }) => {
  const renderSection = (item) => {
    return Object.entries(item.questions).map(([section, value], index) => {
      return (
        <TouchableOpacity
          key={index + 1}
          style={[
            styles.sectionHeader,
            {
              borderBottomWidth:
                Object.keys(item.questions).length - 1 === index ? 0 : 1
            }
          ]}
          onPress={() => onAssignedDetail(item, section)}
        >
          <ResponsiveText style={styles.kpiText}>
            {toTitleCase(section.replace("_", " "))}
          </ResponsiveText>
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <ResponsiveText style={styles.totalCount}>
              {(section === "section_1" || section === "section_3") &&
                renderPercentage(
                  item?.score[section]?.obtained || 0,
                  item?.score[section]?.total || 0,
                  item?.score[section]?.percentage || 0
                )}
            </ResponsiveText>
            {Icons.rightArrow()}
          </View>
        </TouchableOpacity>
      );
    });
  };

  return (
    <View key={item.user._id}>
      <View style={styles.header}>
        <ResponsiveText style={styles.text}>
          {item.user.name}
          <ResponsiveText style={styles.totalCount}>
            {" "}
            {getPercentage(item?.score?.total || "")}
          </ResponsiveText>
        </ResponsiveText>
        <ResponsiveText style={styles.text}>
          {item.assessment.code}
        </ResponsiveText>
      </View>

      {renderSection(item)}
    </View>
  );
};

export default ListItem;

const styles = {
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.background,
    paddingVertical: 20,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginTop: 10
  },
  sectionHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderBottomColor: colors.background,
    marginTop: 5
  },
  text: {
    color: colors.SecondaryText
  },
  kpiText: {
    paddingVertical: 5,
    color: colors.SecondaryText,
    textAlighn: "center"
  },
  headerText: {
    color: colors.SubHeading
  },
  content: {
    backgroundColor: colors.primaryText,
    alignItems: "center",
    marginHorizontal: 25
  },
  totalCount: {
    fontSize: isBrowserScreen() ? wp("0.06%") : wp("0.9%"),
    color: colors.SecondaryText
  }
};
