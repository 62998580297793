import { combineReducers } from 'redux';
import UIState from './UIState';
import AppState from './AppState';

const appReducer = combineReducers({
    UIState,
    AppState
})
const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT_SUCCESS') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;