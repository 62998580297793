const defaultState = {
  allAssessments: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_ALL_ASSESSMENTS_SUCCESS": {
      return {
        ...state,
        allAssessments: action.assessments
      };
    }
    default:
      return state;
  }
};
