import React, { useEffect, useMemo, useState } from "react";
import { Keyboard, Platform, TouchableOpacity, View } from "react-native";
import { showMessage } from "react-native-flash-message";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useDispatch, useSelector } from "react-redux";
import { submitAnswer } from "../actions/AssignedAssessments";
import { showMessageData } from "../actions/AvailableAssesments";
import colors from "../config/color";
import { isBrowserScreen, onValidate } from "../utils/helpers";
import Button from "./Button";
import Content from "./Content";
import InputField from "./InputField";
import ResponsiveText from "./ResponsiveText";

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const DynamicForm = ({ questions, updatedAnswers, readOnly }) => {
  const { platform } = useSelector((state) => state.UIState.platform);
  const [questionList, setQuestionList] = useState([]);
  const [bottomMargin, setBottomMargin] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const arrayList = [];
    questions.forEach((item) => {
      arrayList.push({ ...item, activeNumber: null });
    });
    setQuestionList(arrayList);
  }, []);

  const onRating = (item, parentIndex) => {
    let isValid = true;

    if (questionList[parentIndex]?.commentType === "number") {
      // regex to check empty string or number is entered
      if (item === "") {
        isValid = true;
      } else if (!onValidate("number", item)) {
        isValid = false;
        showMessage({
          ...showMessageData,
          message: "Only Numbers are allowed",
          type: "danger"
        });
      }
    }

    if (isValid) {
      let markers = [...questionList];
      markers[parentIndex] = {
        ...markers[parentIndex],
        [questionList[parentIndex]?.responseType || "response"]: item
      };
      setQuestionList(markers);
    }
  };

  useMemo(() => {
    updatedAnswers(questionList);
  }, [questionList]);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        setBottomMargin(wp("80%"));
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setBottomMargin(0);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    const answerObj = {};
    questionList.forEach((item) => {
      answerObj.answers = {
        ...answerObj.answers,
        [item._id]: { [item.responseType]: item[item.responseType] }
      };
    });
    const res = await dispatch(submitAnswer(answerObj));
    updatedAnswers(res);
    setLoading(false);
  };

  const renderFields = (item, index) => {
    let keyboardType = "default";
    let placeholder = "Enter comment here";
    if (item.commentType === "string") {
      keyboardType = "default";
    }
    if (item.commentType === "number") {
      keyboardType = "numeric";
      placeholder = "Enter number here";
    }
    if (item.commentType === "boolean") {
      keyboardType = "numeric";
      placeholder = "Enter YES or NO";
    }

    return (
      <InputField
        editable={!readOnly}
        multiline
        numberOfLines
        keyboardType={keyboardType}
        inputContainer={{ height: platform ? wp("7%") : wp("20%") }}
        placeholder={placeholder}
        value={item.comment}
        onChangeText={(e) => onRating(e, index, item.commentType)}
      />
    );
  };

  return (
    <Content KeyboardAvoidingView>
      <View
        style={{
          marginBottom: Platform.OS === "ios" ? bottomMargin : 0,
          padding: 10,
          marginTop: 10
        }}
      >
        {questionList.map((item, index) => {
          return (
            <>
              <ResponsiveText style={styles.textQuestion}>
                {`${item.title} ${index + 1}: ${item.text}`}
              </ResponsiveText>
              {item.responseType !== "response" ? (
                renderFields(item, index)
              ) : (
                <View style={styles.ratingContainer}>
                  {numbers.map((child) => {
                    return (
                      <TouchableOpacity
                        disabled={readOnly}
                        onPress={() => onRating(child, index, item.commentType)}
                        style={[
                          styles.touchable,
                          {
                            backgroundColor:
                              item.response === child
                                ? colors.activeTabBarColor
                                : colors.primaryText,
                            borderColor:
                              item.response === child
                                ? colors.activeTabBarColor
                                : colors.borderColor
                          }
                        ]}
                      >
                        <ResponsiveText
                          style={{
                            color:
                              item.response === child
                                ? colors.primaryText
                                : colors.secondaryText
                          }}
                        >
                          {child}
                        </ResponsiveText>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              )}
              <View style={styles.separator} />
            </>
          );
        })}
        {!readOnly ? (
          <Button
            loading={loading}
            containerStyle={styles.submitBtn}
            text="Submit"
            onPress={onSubmit}
          />
        ) : null}
      </View>
    </Content>
  );
};

export default DynamicForm;

const styles = {
  text: {
    color: colors.secondaryText
  },
  submitBtn: {
    marginHorizontal: 10
  },
  textQuestion: {
    fontWeight: "bold",
    color: colors.secondaryText,
    marginBottom: 10
  },
  touchable: {
    borderWidth: 2,
    borderColor: colors.borderColor,
    borderRadius: 10,
    width: "17%",
    height: isBrowserScreen() ? wp("3%") : wp("9%"),
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 12
  },
  ratingContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center"
  },
  row: {
    flex: 1,
    justifyContent: "space-around"
  },
  separator: {
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    marginBottom: 15,
    marginTop: 8
  }
};
