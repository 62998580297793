import React, { useCallback, useState } from "react";
import {
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  Platform,
  BackHandler,
} from "react-native";
import Container from "../../common/Container";
import InputField from "../../common/InputField";
import CommonText from "../../common/CommonText";
import Button from "../../common/Button";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../actions/User";
import { showMessage } from "react-native-flash-message";
import { onValidate } from "../../utils/helpers";
import AsyncStorage from "@react-native-async-storage/async-storage";

const showMessageData = { position: "top", autoHide: true, duration: 5000 };

const UpdatePassword = ({ navigation }) => {
  const dispatch = useDispatch();
  const { platform } = useSelector((state) => state.UIState.platform);
  const userInfo = useSelector((state) => state.AppState.userData.userData);
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onUpdate = async () => {
    if (!newPassword.length || !retypePassword.length) {
      showMessage({
        ...showMessageData,
        message: "Please fill all fields",
        type: "danger",
      });
    } else {
      if (!onValidate("password", newPassword)) {
        showMessage({
          ...showMessageData,
          message: "Invalid Password",
          type: "danger",
        });
      } else if (newPassword !== retypePassword) {
        showMessage({
          ...showMessageData,
          message: "Password does not match",
          type: "danger",
        });
      } else {
        setLoading(true);
        const loginData = await dispatch(updatePassword(newPassword));
        setLoading(false);
        if (loginData.success) {
          AsyncStorage.clear();
          navigation.navigate("Login");
        }
      }
    }
  };

  return (
    <Container style={styles.container}>
      <KeyboardAvoidingView
        behavior={platform ? "padding" : "height"}
        style={styles.container}
      >
        <View style={styles.headerContainer}>
          <CommonText type="heading">Assessment Manager</CommonText>
        </View>
        <View
          style={[
            styles.fieldContainer,
            { paddingHorizontal: platform ? wp("3%") : wp("5%") },
          ]}
        >
          <CommonText type="heading">Login with your credentials</CommonText>
          <InputField
            placeholder="Email"
            editable={false}
            value={userInfo?.email}
          />
          <InputField
            placeholder="New Password"
            secureTextEntry
            value={newPassword}
            onChangeText={(e) => setNewPassword(e)}
          />
          <InputField
            placeholder="Re-type Password"
            secureTextEntry
            value={retypePassword}
            onChangeText={(e) => setRetypePassword(e)}
          />
          <Button loading={loading} text="Update Password" onPress={onUpdate} />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default UpdatePassword;

const styles = {
  headerContainer: {
    height: wp("10%"),
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  fieldContainer: {
    flex: 1,
    justifyContent: "center",
  },
  bottomView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
