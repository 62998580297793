import { SET_PLATFORM } from "../../config/actionTypes";
import { isBrowserScreen } from "../../utils/helpers";

const defaultState = {
  platform: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_PLATFORM: {
      return {
        ...state,
        platform: isBrowserScreen(),
      };
    }
    default:
      return state;
  }
};
