const defaultState = {
    email: '',
    password: '',
    error: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_LOGIN_STATE': {
            const {
                email,
                password
            } = action;
            return {
                ...state,
                email,
                password
            }
        }
        case 'LOGIN_ERROR': {
            const {
                error
            } = action
            return {
                ...state,
                error
            }
        }
        default:
            return state;
    }
}