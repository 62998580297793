import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  SET_AUTH_TOKEN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_REGISTER_SUCCESS,
  USER_VERIFY_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
} from "../../config/actionTypes";
const defaultState = {
  authToken: null,
  userData: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_AUTH_TOKEN: {
      try {
        // set locally
        AsyncStorage.setItem("jwtToken", action.token);
      } catch (error) {
        console.log(error);
      }
      return {
        ...state,
        authToken: action.token,
      };
    }
    case USER_LOGIN_SUCCESS: {
      if (action.user) {
        try {
          // set locally
          AsyncStorage.setItem("userData", JSON.stringify(action.user));
        } catch (error) {
          console.log(error);
        }
      }
      return {
        ...state,
        userData: action.user,
      };
    }
    case USER_LOGIN_ERROR: {
      return {
        ...state,
        userData: action.error,
      };
    }
    case USER_REGISTER_SUCCESS: {
      return {
        ...state,
        userData: action.user,
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      try {
        // set locally
        AsyncStorage.setItem("forgot", JSON.stringify({ forgot: true }));
      } catch (error) {
        console.log(error);
      }
      return {
        ...state,
        userData: action.user,
      };
    }
    case USER_VERIFY_SUCCESS: {
      if (action.user !== null) {
        try {
          // set locally
          AsyncStorage.setItem("userData", JSON.stringify(action.user));
        } catch (error) {
          console.log(error);
        }
      }
      return {
        ...state,
        userData: action.user,
      };
    }
    default:
      return state;
  }
};
