import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import baseURL from "../../utils/config";
import store from "../../store";

const API = axios.create({ baseURL });

API.interceptors.request.use((req) => {
  const {
    AppState: { userData },
  } = store.getState();
  req.headers.Authorization = userData?.authToken;
  return req;
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      // logout
      AsyncStorage.clear();
      global.navigation.navigate("Login");
    }
    return Promise.reject(error);
  }
);

export default API;
