import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import Button from "../../common/Button";
import CommonText from "../../common/CommonText";
import Container from "../../common/Container";
import OTPTextView from "react-native-otp-textinput";
import ResponsiveText from "../../common/ResponsiveText";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { showMessage } from "react-native-flash-message";
import { useDispatch, useSelector } from "react-redux";
import { verifyCode, resendCode } from "../../actions/User";

const showMessageData = { position: "top", autoHide: true, duration: 5000 };

const VerifyOTP = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const { platform } = useSelector((state) => state.UIState.platform);
  const forgotStatus = route.params?.forgot;
  const userInfo = useSelector((state) => state.AppState.userData.userData);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeText = (e) => {
    setOtp(e);
  };

  const onVerifyCode = async () => {
    if (otp.length < 6) {
      showMessage({
        ...showMessageData,
        message: "Please fill all fields",
        type: "danger",
      });
    } else {
      setLoading(true);
      const result = await dispatch(verifyCode(userInfo.email, otp));
      setLoading(false);
      if (result.success && forgotStatus) {
        navigation.navigate("UpdatePassword");
      } else if (result.success) {
        navigation.navigate("Home");
      }
    }
  };

  const onResendCode = async () => {
    await dispatch(resendCode(userInfo?.email));
  };

  return (
    <Container style={styles.container}>
      <View
        style={[
          styles.headerContainer,
          { height: platform ? wp("7%") : wp("20%") },
        ]}
      >
        <CommonText type="heading">Verify OTP</CommonText>
        <CommonText type="heading">{userInfo?.name}</CommonText>
        <CommonText type="normal">{userInfo?.email}</CommonText>
      </View>
      <View style={styles.fieldContainer}>
        <CommonText type="normal">Please enter otp code : </CommonText>
        <OTPTextView
          containerStyle={styles.otpContainer}
          inputCount={6}
          keyboardType="numeric"
          handleTextChange={(text) => {
            onChangeText(text);
          }}
        />
        <Button loading={loading} text="Verify" onPress={onVerifyCode} />
        <View style={styles.bottomBtns}>
          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={onResendCode}
          >
            <ResponsiveText style={{ color: "red" }}>
              Resend code
            </ResponsiveText>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ alignItems: "center" }}
            onPress={() => navigation.navigate("Login")}
          >
            <ResponsiveText style={{ color: "red" }}>Login?</ResponsiveText>
          </TouchableOpacity>
        </View>
      </View>
    </Container>
  );
};

export default VerifyOTP;
const styles = {
  headerContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  fieldContainer: {
    flex: 1,
    justifyContent: "center",
    paddingHorizontal: wp("5%"),
  },
  otpContainer: {
    justifyContent: "center",
  },
  bottomBtns: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
