import * as api from "../lib/api/AssessmentService";
import { showMessage } from "react-native-flash-message";
import { showMessageData } from "./AvailableAssesments";
import { GET_MY_ASSIGNMENTS_SUCCESS } from "../config/actionTypes";

export const getMyAssignments = () => async (dispatch) => {
  try {
    const data = await api.getMyAssignments();
    dispatch({
      type: GET_MY_ASSIGNMENTS_SUCCESS,
      assessments: data.assessments,
    });
    return data.assessments;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger",
    });
    return error.response.data;
  }
};

export const submitAnswer = (body) => async () => {
  try {
    const data = await api.submitAnswer(body);
    showMessage({
      ...showMessageData,
      message: data.message,
      type: "success",
    });
    return data;
  } catch (error) {
    const message = error.response.data.message;
    showMessage({
      ...showMessageData,
      message,
      type: "danger",
    });
    return error.response.data;
  }
};
