import React from "react";

import Main from "../../src/screens/Main";
import Login from "../../src/screens/Authentication/Login";
import ForgotPassword from "../../src/screens/Authentication/ForgotPassword";
import VerifyOTP from "../../src/screens/Authentication/VerifyOTP";
import Register from "../../src/screens/Authentication/Register";
import UpdatePassword from "../../src/screens/Authentication/UpdatePassword";
import Home from "../../src/screens/Assesments/Home";
import AvailableAssessments from "../../src/screens/Assesments/AvailableAssessments";
import InitiateAssesment from "../../src/screens/Assesments/InitiateAssesment";
import ManageAssignments from "../screens/Assesments/ManageAssignments";
import AssignedAssessments from "../screens/Assesments/AssignedAssessments";
import UserSelection from "../screens/Assesments/ManageAssignments/UserSelection";
import ManageAssessment from "../screens/Assesments/ManageAssessments";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import AssessmentDetail from "../screens/Assesments/AssessmentDetail";
import AnswerQuestions from "../screens/Assesments/AssignedAssessments/AnswerQuestions";
import Report from "../screens/Report";

const MainStack = () => {
  const Stack = createNativeStackNavigator();
  const HomeStack = createNativeStackNavigator();

  return (
    <Stack.Navigator
      initialRouteName="Main"
      screenOptions={{ gestureEnabled: false }}
    >
      <Stack.Screen
        name="Login"
        component={Login}
        options={{ title: "Login", headerShown: false }}
      />
      <Stack.Screen
        name="ForgotPassword"
        component={ForgotPassword}
        options={{ title: "ForgotPassword", headerShown: false }}
      />
      <Stack.Screen
        name="VerifyOTP"
        component={VerifyOTP}
        options={{ title: "VerifyOTP", headerShown: false }}
      />
      <Stack.Screen
        name="UpdatePassword"
        component={UpdatePassword}
        options={{ title: "UpdatePassword", headerShown: false }}
      />
      <Stack.Screen
        name="Register"
        component={Register}
        options={{ title: "Register", headerShown: false }}
      />
      <Stack.Screen
        name="Main"
        component={Main}
        options={{ title: "Main", headerShown: false }}
      />
      <HomeStack.Screen
        name="Home"
        component={Home}
        options={{ title: "Home", headerShown: false }}
      />
      <HomeStack.Screen
        name="AvailableAssessments"
        component={AvailableAssessments}
        options={{ title: "AvailableAssesments", headerShown: false }}
      />
      <HomeStack.Screen
        name="InitiateAssesment"
        component={InitiateAssesment}
        options={{ title: "InitiateAssesment", headerShown: false }}
      />
      <HomeStack.Screen
        name="ManageAssessment"
        component={ManageAssessment}
        options={{ title: "ManageAssessment", headerShown: false }}
      />
      <HomeStack.Screen
        name="AssignedAssessments"
        component={AssignedAssessments}
        options={{ title: "AssignedAssessments", headerShown: false }}
      />
      <HomeStack.Screen
        name="AssessmentDetail"
        component={AssessmentDetail}
        options={{ title: "AssessmentDetail", headerShown: false }}
      />
      <HomeStack.Screen
        name="ManageAssignments"
        component={ManageAssignments}
        options={{ title: "ManageAssignments", headerShown: false }}
      />
      <HomeStack.Screen
        name="AnswerQuestions"
        component={AnswerQuestions}
        options={{ title: "AnswerQuestions", headerShown: false }}
      />

      <HomeStack.Screen
        name="UserSelection"
        component={UserSelection}
        options={{ title: "UserSelection", headerShown: false }}
      />
      <HomeStack.Screen
        name="Report"
        component={Report}
        options={{ title: "Report", headerShown: false }}
      />
    </Stack.Navigator>
  );
};
export default MainStack;
