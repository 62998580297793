import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import Container from "../../common/Container";
import InputField from "../../common/InputField";
import ResponsiveText from "../../common/ResponsiveText";
import CommonText from "../../common/CommonText";
import Button from "../../common/Button";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../actions/User";
import { showMessage } from "react-native-flash-message";
import { onValidate } from "../../utils/helpers";
import Content from "../../common/Content";

const showMessageData = { position: "top", autoHide: true, duration: 5000 };

const Register = ({ navigation }) => {
  const dispatch = useDispatch();
  const { platform } = useSelector((state) => state.UIState.platform);
  const [registerData, setRegisterData] = useState({
    email: "",
    password: "",
    retype: "",
    name: "",
    emp_code: "",
  });
  const [loading, setLoading] = useState(false);

  const onChangeInput = (e, type) => {
    setRegisterData({
      ...registerData,
      [type]: e,
    });
  };

  const onRegisterUser = async () => {
    const { name, email, password, retype, emp_code } = registerData;
    const trimmedEmail = email.toLowerCase().trim();
    const trimmedName = name.trim();
    const trimmedEmp = emp_code.toLowerCase().trim();
    if (
      !trimmedName.length ||
      !trimmedEmail.length ||
      !password.length ||
      !retype.length ||
      !trimmedEmp.length
    ) {
      showMessage({
        ...showMessageData,
        message: "Please fill all fields",
        type: "danger",
      });
    }

    setRegisterData({
      ...registerData,
      email: email.trim(),
      name: trimmedName,
      emp_code: emp_code.trim(),
    });

    if (!onValidate("email", trimmedEmail)) {
      showMessage({
        ...showMessageData,
        message: "Invalid Email",
        type: "danger",
      });
    }
    if (!onValidate("password", password)) {
      showMessage({
        ...showMessageData,
        message: "Password should be at least 6 characters",
        type: "danger",
      });
    }
    if (!onValidate("name", name)) {
      showMessage({
        ...showMessageData,
        message: "Invalid Name",
        type: "danger",
      });
    }
    if (!onValidate("emp_code", emp_code)) {
      showMessage({
        ...showMessageData,
        message: "Invalid Employee Code, Alphanumeric only",
        type: "danger",
      });
    }
    if (password !== retype) {
      showMessage({
        ...showMessageData,
        message: "Password should be the same",
        type: "danger",
      });
    }
    if (
      onValidate("email", trimmedEmail) &&
      onValidate("emp_code", trimmedEmp) &&
      onValidate("name", trimmedName) &&
      onValidate("password", password)
    ) {
      setLoading(true);
      const registerData = await dispatch(
        register(trimmedName, trimmedEmail, password, trimmedEmp)
      );
      registerData.success && navigation.navigate("VerifyOTP");
      setLoading(false);
    }
  };

  return (
    <Container style={styles.container}>
      <Content KeyboardAvoidingView>
        <View style={styles.headerContainer}>
          <CommonText type="heading">Assessment Manager</CommonText>
        </View>
        <View style={styles.innerContainer}>
          <View
            style={[
              styles.fieldContainer,
              {
                paddingHorizontal: platform ? wp("3%") : wp("5%"),
              },
            ]}
          >
            <CommonText style={{ textAlign: "center" }} type="heading">
              Registration
            </CommonText>
            <InputField
              placeholder="Name"
              value={registerData.name}
              onChangeText={(e) => onChangeInput(e, "name")}
            />
            <InputField
              placeholder="Employee Code"
              value={registerData.emp_code}
              onChangeText={(e) => onChangeInput(e, "emp_code")}
            />
            <InputField
              placeholder="Email"
              value={registerData.email}
              onChangeText={(e) => onChangeInput(e, "email")}
            />
            <InputField
              placeholder="Password"
              secureTextEntry
              value={registerData.password}
              onChangeText={(e) => onChangeInput(e, "password")}
            />
            <InputField
              placeholder="Re-type Password"
              secureTextEntry
              value={registerData.retype}
              onChangeText={(e) => onChangeInput(e, "retype")}
            />
            <Button
              loading={loading}
              text="Register"
              onPress={onRegisterUser}
            />
            <TouchableOpacity
              style={{ alignItems: "center" }}
              onPress={() => navigation.navigate("Login")}
            >
              <ResponsiveText style={{ color: "red" }}>
                Already have an account? Login
              </ResponsiveText>
            </TouchableOpacity>
          </View>
        </View>
      </Content>
    </Container>
  );
};

export default Register;

const styles = {
  headerContainer: {
    height: wp("20%"),
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    marginVertical: 20,
    paddingVertical: 20,
  },
  container: {
    flex: 1,
  },
  fieldContainer: {
    flex: 1,
    justifyContent: "center",
  },
};
