import React, { useEffect } from "react";
import { Platform, View } from "react-native";
import Container from "../../common/Container";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useDispatch } from "react-redux";
import { setAuthToken, setUserData, setPlatform } from "../../actions/User";
import colors from "../../config/color";
import Loading from "../../common/Loading";

export const Main = ({ navigation }) => {
  const dispatch = useDispatch();
  global.navigation = navigation;

  useEffect(() => {
    dispatch(setPlatform(Platform.OS));
    setTimeout(async () => {
      const value = await AsyncStorage.getItem("jwtToken");
      const user = await AsyncStorage.getItem("userData");
      const forgot = await AsyncStorage.getItem("forgot");

      let forgotStatus = null;

      if (forgot !== null) {
        forgotStatus = JSON.parse(forgot).forgot;
        AsyncStorage.clear();
      }

      if (!!value?.length && !forgotStatus) {
        dispatch(setAuthToken(value));
        dispatch(setUserData(JSON.parse(user)));
        navigation.navigate("Home");
      } else {
        navigation.navigate("Login");
      }
    }, 1000);
  }, []);

  return (
    <Container>
      <View style={styles.container}>
        <Loading />
      </View>
    </Container>
  );
};

const styles = {
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.primaryText,
  },
  text: {
    fontSize: "11%",
    color: colors.secondaryText,
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default Main;
