import React, { Component } from "react";
import { TouchableOpacity, View, TextInput, Platform } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useSelector } from "react-redux";
import colors from "../config/color";
import { isBrowserScreen } from "../utils/helpers";
import ResponsiveText from "./ResponsiveText";

const AppHeader = (props) => {
  return (
    <View style={[styles.customStyle, props.containerStyle]}>
      <View style={[styles.left, props.leftStyle]}>
        {props.left && (
          <TouchableOpacity style={{ padding: 10 }} onPress={props.leftPress}>
            {props.left}
          </TouchableOpacity>
        )}
      </View>
      <View style={[styles.body, props.bodyStyle]}>
        {props.body && (
          <ResponsiveText style={styles.bodyStyle}>{props.body}</ResponsiveText>
        )}
      </View>

      <View style={[styles.right, props.rightStyle]}>
        {props.right && (
          <TouchableOpacity onPress={props.rightPress}>
            {props.right}
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};
export default AppHeader;
const styles = {
  customStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: colors.primaryText,
    paddingHorizontal: 10,
    height: isBrowserScreen() ? wp("5%") : wp("15%"),
    borderBottomWidth: 1,
    borderBottomColor: colors.borderColor,
    shadowColor: colors.secondaryText,
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1,
    zIndex: 10000
  },
  left: {
    // flex: 1,
  },
  body: {
    flex: 1,
    alignItems: "center"
  },
  right: {
    // flex: 1,
    alignItems: "flex-end",
    marginHorizontal: 10
  },
  bodyStyle: {
    fontSize: isBrowserScreen() ? "2%" : "5%",
    color: colors.secondaryText
  }
};
