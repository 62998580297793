import React, { useEffect, useMemo, useState } from "react";
import { Keyboard, KeyboardAvoidingView, Platform, View } from "react-native";
import Content from "../../common/Content";
import InputField from "../../common/InputField";
import Button from "../../common/Button";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import Container from "../../common/Container";
import { useSelector } from "react-redux";

const KpiForm = ({
  editMode,
  onSubmit,
  loading,
  showKickOff,
  loadingKickOff,
  handleKickOff
}) => {
  const { platform } = useSelector((state) => state.UIState.platform);

  const [kpiForm, setKpiForm] = useState(editMode ? editMode.questions : []);
  const [bottomMargin, setBottomMargin] = useState(0);

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        setBottomMargin(wp("80%"));
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setBottomMargin(0);
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  useMemo(() => {
    setKpiForm(editMode ? editMode.questions : []);
  }, [editMode]);

  const handleChange = (e, idx) => {
    let markers = [...kpiForm];
    markers[idx] = { ...markers[idx], text: e };
    setKpiForm(markers);
  };

  const handleSubmit = () => {
    onSubmit(kpiForm);
  };

  return (
    <Content KeyboardAvoidingView>
      <View style={{ marginBottom: Platform.OS === "ios" ? bottomMargin : 0 }}>
        {kpiForm?.map((_, i) => {
          return (
            <InputField
              editable={!kpiForm[i]?.kickedOff}
              multiline
              numberOfLines
              inputContainer={{ height: platform ? wp("7%") : wp("20%") }}
              subLeft={`Your KPI No. ${i + 1}`}
              placeholder={`Enter KPI here`}
              value={kpiForm[i]?.text || ""}
              onChangeText={(e) => handleChange(e, i)}
            />
          );
        })}
        {!kpiForm[0]?.kickedOff && (
          <Button
            loading={loading}
            containerStyle={styles.submitBtn}
            text="Submit"
            onPress={handleSubmit}
          />
        )}
        {showKickOff && !kpiForm[0]?.kickedOff && (
          <Button
            loading={loadingKickOff}
            containerStyle={styles.kickOffBtn}
            text="Kick Off"
            onPress={() => {
              handleKickOff();
            }}
          />
        )}
      </View>
    </Content>
  );
};

export default KpiForm;

const styles = {
  container: {
    paddingHorizontal: 10,
    flex: 1,
    justifyContent: "center"
  },
  content: {
    flex: 1
  },
  headerText: {
    textAlign: "center"
  },
  kickOffBtn: {
    marginTop: wp("0%")
  }
};
