import React from "react";
import { useSelector } from "react-redux";
import AppHeader from "../../../common/AppHeader";
import Container from "../../../common/Container";
import DynamicForm from "../../../common/DynamicForm";
import Icons from "../../../config/icons";

const AnswerQuestions = ({ navigation, route }) => {
  const { assessmentDetail } = useSelector(
    (state) => state.UIState.assessments
  );
  const { section, readOnly } = route.params;

  return (
    <Container>
      <AppHeader
        body="Answer Questions"
        leftPress={() => navigation.goBack()}
        left={Icons.back()}
      />
      <DynamicForm
        updatedAnswers={(res) => res.success && navigation.goBack()}
        questions={assessmentDetail.questions[section]}
        readOnly={readOnly}
      />
    </Container>
  );
};

export default AnswerQuestions;
