import React from "react";
import { TouchableOpacity } from "react-native";
import colors from "../config/color";
import Icons from "../config/icons";
import ResponsiveText from "./ResponsiveText";

const SelectItem = ({ onPress, title, selectedList, item }) => {
  const _isSelected = (item) => {
    if (selectedList.indexOf(item) == -1) {
      return false;
    }
    return true;
  };
  return (
    <TouchableOpacity onPress={onPress} style={styles.item}>
      <ResponsiveText style={styles.title}>{title}</ResponsiveText>
      {_isSelected(item) ? Icons.check() : Icons.unCheck()}
    </TouchableOpacity>
  );
};

export default SelectItem;

const styles = {
  title: {
    color: colors.secondaryText,
  },
  item: {
    paddingVertical: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
};
